<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='24' :md='6' :xl='6' :style="{ marginBottom: '24px' }">
        <chart-card :loading='loading' title='待核销' :total='ext1'>
          <a-tooltip title='待核销' slot='action'>
            <a-icon type='info-circle-o' />
          </a-tooltip>
        </chart-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='checkStatus'>
        <a-select v-model="form.checkStatus" placeholder="状态" style="width: 250px">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option :value="3">
            待选择
          </a-select-option>
          <a-select-option :value="0">
            待核销
          </a-select-option>
          <a-select-option :value="1">
            已完成
          </a-select-option>
          <a-select-option :value="2">
            已退单
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.memberMobile' style="width: 250px" placeholder="手机号" />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
<!--        <a-button @click='initialDeliver2'>-->
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 100}' :data='loadData'>
        <span slot='mobile' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              {{ text }}
            </a>
          </template>
        </span>
        <span slot='checkStatus' slot-scope='text'>
          <template>
            <a-badge dot v-if='text=="3"' :status='"info"' :text='"待选择"'></a-badge>
            <a-badge dot v-else-if='text=="0"' :status='"processing"' :text='"待核销"'></a-badge>
            <a-badge dot v-else-if='text=="1"' :status='"success"' :text='"已完成"'></a-badge>
            <a-badge dot v-else-if='text=="2"' :status='"success"' :text='"已退单"'></a-badge>
            <a-badge dot v-else :status='"default"' :text='"其他"'></a-badge>
          </template>
        </span>

        <span slot='id' slot-scope='text'>
           <a @click='openDetail(text)'> {{text}}</a>
        </span>

        <span slot='scene' slot-scope='text,record'>
           <template v-if="text=='1'">{{record.spreaderName }}</template>
           <template v-if="text=='2'">{{record.merchantBankAccountName }}</template>
        </span>

        <span slot='startDate' slot-scope='text'>
            {{showMonthMin(text)}}
        </span>

        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>

              <a @click='initialDeliver1(record)' v-if="!record.meetId">选择时间</a>


              <template v-else>
                  <a-popconfirm title='确定核销吗？' ok-text='确认' cancel-text='取消' @confirm='handleWriteOff(record)'  v-if="record.checkStatus=='0'">
                  <a href='#'>核销</a>
                </a-popconfirm>
                  <a @click='initialDeliver1(record)'  v-if="record.meetId && record.checkStatus!='3' && record.checkStatus!='1' && record.checkStatus!='2'">改期</a>

                  <a-popconfirm title='确定取消吗？' ok-text='确认' cancel-text='取消' @confirm='cancelActive(record)'  v-if="record.checkStatus=='0'">
                    <a href='#'  v-if="record.checkStatus!='1'||record.checkStatus!='2'">取消</a>
                  </a-popconfirm>

                  <a @click='initialDeliver2(record)' v-if="record.checkStatus!='3' && record.checkStatus!='1' && record.checkStatus!='2'">退单</a>
              </template>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

        <a-modal title='选择时间' :destroyOnClose='true' :maskClosable='true'
                 :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
                 ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
          <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
            <a-form-model-item label='会议场次' prop='meetId'>
              <a-select v-model="deliver1.form.meetId" placeholder="会议场次" style="width: 80%">
                <a-select-option :value="item.id" v-for="(item, index) in actList" :key="index">
                  {{showMonthMin(item.startDate)}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </a-modal>

    <a-modal title='退单' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :confirmLoading='deliver2.loading'>
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='退单方式' prop='returnType'>
          <a-radio-group name="type" v-model="deliver2.form.returnType">
            <a-radio :value="1">
              原路退回
            </a-radio>
            <a-radio :value="2">
              线下退款
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {
  queryApplyList, getApplyDashboard, deleteMerchantAudit,
  queryActWriteOff499, cancelActive499
} from '@/api/member'
import {putActivity, queryActivity499} from "@/api/activity";
import {checkActivity499, orderReturnActivity499, selectTimeActivity499} from "@/api/member-order";
import moment from "moment";

const columns = [
  {
    width: 120,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 150,
    title: '购买人姓名',
    dataIndex: 'memberName',
  },
  {
    width: 150,
    title: '购买人手机号',
    dataIndex: 'memberMobile',
  },
  {
    width: 150,
    title: '推荐人姓名',
    dataIndex: 'spreaderName',
  },
  {
    width: 150,
    title: '下单时间',
    dataIndex: 'createTime',
  },
  {
    width: 110,
    title: '收款人',
    dataIndex: 'scene',
    scopedSlots: { customRender: 'scene' }
  },
  {
    width: 150,
    title: '会议时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' }
  },
  {
    width: 80,
    title: '状态',
    dataIndex: 'checkStatus',
    scopedSlots: { customRender: 'checkStatus' }
  },
  {
    width: 300,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'MemberApplyList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      ext1:0,//待核销数
      actList:[],//会议列表
      form: {
        keyword: ""
      },
      //统计数据
      loading: true,
      dashboard: {},
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter, this.form);
        let checkStatus = params.checkStatus;
        if(checkStatus){
          if(checkStatus==3){//就是待选择条件查询，需要删除本条件，增加selected 条件
              params.selected = 'false';//增加
              delete params.checkStatus;//删除checkStatus
          }else{
              delete params.selected;//删除checkStatus
          }
        }

        return queryActWriteOff499(params)
          .then(datum => {
            this.ext1 = datum.ext1|0;
            return datum
          })
      },
      deliver1: {
        visible: false,
        loading: false,
        form: {},
        rules: {
          meetId: [{ required: true, message: '请选择会议场次', trigger: 'change' }],
        },
      },
      deliver2: {
        visible: false,
        loading: false,
        form: {},
        rules: {
          returnType: [{ required: true, message: '请选择退单方式', trigger: 'change' }],
        },
      },
    }
  },
  created() {
    this.getDashboard()
  },
  methods: {
    showMonthMin(_date){
      if(_date){
        const date = new Date(_date);
        const formattedDate = moment(date).format('YYYY-MM-DD HH:mm');
        return formattedDate;
      }else{
        return  "";
      }

    },
    getDashboard() {
      getApplyDashboard().then(result => {
        this.dashboard = result;
        this.loading = false;
      })
    },
    handleDelete(record) {
      deleteMerchantAudit({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleEdit(record) {
      this.$router.push({ path: '/member/apply/edit', query: { id: record.id } })
    },
    initialDeliver1(record) {
      let params ={pageSize: 99};
      queryActivity499(params).then(result => {
        debugger
        this.actList = result.records;
        this.deliver1.form.orderId = record.id;
        this.deliver1.loading = false
        this.deliver1.visible = true
      })
    },
    //选择会议时间
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          this.deliver1.loading = true;
          let params = Object.assign(this.deliver1.form);
          selectTimeActivity499(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    initialDeliver2(record) {
      this.deliver2.form.orderId = record.id;
      this.deliver2.loading = false
      this.deliver2.visible = true
    },
    //退单
    handleDeliver2() {
      this.$refs.form2.validate((result) => {
        if (result) {
          this.deliver2.loading = true;
          let params = Object.assign(this.deliver2.form);
          orderReturnActivity499(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
            this.deliver2.loading = false;
          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    //取消订单时间
    cancelActive(record){
      cancelActive499({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    //核销接口哦
    handleWriteOff(record){
      checkActivity499({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    //打开详情页
    openDetail(id){
      this.$router.push({ path: '/order/writeoff/detail', query: { id: id } })
    }

  }
}
</script>
